/*
 * @Author: Alan
 * @Date: 2022-03-07 11:49:02
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-06 00:09:32
 * @Description: Banner
 * @FilePath: /F1-M2-WEB-Code/components/Banner/img.js
 */
import React from "react";
import Router from "next/router";
import Image from "next/image";
import { getQueryVariable } from '$ACTIONS/helper'
import { mapGameProduct, getGame2ndLevelRouteName } from "$DATA/gameUtils";
import { connect } from 'react-redux';
import { message } from "antd";
import { promotionActions } from "$STORE/promotionSlice";
import { gameActions } from "$STORE/gameSlice";
import { isAllowGuestOpenGame } from "$ACTIONS/helper";
import LinkHelper from "@/Common/LinkHelper"

// import LazyLoad from 'react-lazyload';

class Banner extends React.Component {
    constructor() {
        super();
        this.state = {
            WalletCloseBanner: false,
        };
    }
    componentDidMount() {
        this.setState({
            WalletCloseBanner: sessionStorage.getItem("WalletCloseBanner"),
        });
    }

    CloseBanner() {
        sessionStorage.setItem("WalletCloseBanner", true);
        this.setState({
            WalletCloseBanner: true,
        });
    }

    /**
     * @description: 轮播图跳转动作
     * @param {*} data
     * @return {*}
     */

    BannerAction(data) {
        let item = data.action;
        let isLogin = localStorage.getItem("access_token");
        if (!item.actionId || item.actionId == 0) {
            return;
        }
        console.log("----------------->", item);
        switch (item.actionId) {
            case 28:
                if (item.url == "/rewards-centre") {
                    if (!isLogin) {
                        this.props.setCacheGame({ type: "smartico" });
                        global.goUserSign("1");
                        return
                    }
                    Router.push("/rewards-centre");
                    return;
                }
                location.href = item.url;
                break;
            case 29:
                if (data.category == "worldcup_main") {
                    Pushgtagdata(
                        "Promo Nav",
                        "View",
                        item.ID + "_PromoTnC_WCPage2022"
                    );
                }
                this.props.changeTab("1");
                Router.push(`/promotions?id=${item.ID}&jumpfrom=BANNER`, `/โปรโมชั่น?id=${item.ID}&jumpfrom=BANNER`);
                break;
            case 30:
                Router.push("/sponsorship");
                break;
            case 31:
                if (!isLogin) {
                    global.goUserSign(getQueryVariable("isAgent") === "1" ? "2" : "1");
                    return;
                }
                global.showDialog({ key: 'wallet:{"type": "deposit"}' });
                break;
            case 32:
            case 34:
                // Deeplink 目前僅CXFUN88-4276使用，且限im
                if (item.sportLaunchType) {
                    if (!isLogin) {
                        global.goUserSign("1");
                        return;
                    }
                    switch (item.sportLaunchType) {
                        case "DEEPLINK":
                            return Router.push(
                                `/กีฬา/im?sportId=${item.sportId}&eventId=${item.eventId}`
                            );
                        case "HOME":
                            return Router.push("/กีฬา/im");
                        default:
                            break;
                    }
                }
                // 查找對應的 gameCateCode
                const gameCategory = this.props.GameCategory.find(cat=>cat.subProviders.some(sub=>sub.code === item.cgmsVendorCode));
                const gameCategoryCode = gameCategory?.code || "";
                const gameCategoryItem = mapGameProduct.find(item=>item.productCode === gameCategoryCode);
                if (!gameCategoryItem) {
					message.error(`"${item.cgmsVendorCode}" is not included in gameSequence, cannot redirect to lobby (FE)`);
					return
				}
                // 查找對應的 providerName
                const providerName = getGame2ndLevelRouteName(gameCategoryCode, item.cgmsVendorCode);
                if (!providerName) {
					message.error(`"${item.cgmsVendorCode}" is not included in providerList, cannot redirect to lobby (FE)`);
					return
				}
                if (item.launchMode == "game_id") {
                    if (!isLogin) {
                        global.goUserSign("1");
                        return;
                    }
                    Router.push(`/${gameCategoryItem.name}/${providerName}?gameid=${item.gameId}`);
                    return;
                }
                if (item.launchMode == "lobby" || item.launchMode == "web_view") {
                    const IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME = isAllowGuestOpenGame(gameCategoryCode, item.cgmsVendorCode);
                    //如果未登录，type = Game 且不在 guestView 清單，弹出登录窗口
                    if (!isLogin && (!IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME && gameCategory?.subProviders?.find(sub=>sub.code === item.cgmsVendorCode)?.type === "Game")) {
                        global.goUserSign("1");
                        return;
                    }
                    Router.push(`/${gameCategoryItem.name}/${providerName}`);
                    return;
                }
                break;
            case 33:
                PopUpLiveChat();
                break;
            case 202211:
                Pushgtagdata(
                    "Game​",
                    "View",
                    item.matchInfo.match_id + "_IMSP_WCPage2022​​"
                );
                Router.push(
                    `/sbtwo/sports-im/detail/?sid=1&eid=${
                        item.matchInfo.event_id
                    }&lid=${30455}&OE=false`
                );
                break;
            default:
                break;
        }
    }

    getBannerHref(data) {
        let item = data.action;
       
        switch (item.actionId) {
            case 28:
                return { href: item.url };

            case 29:
                return {
                   href: `/promotions?id=${item.ID}&jumpfrom=BANNER`,
                   as: `/โปรโมชั่น?id=${item.ID}&jumpfrom=BANNER`
                };

            case 30:
                return { href: `/sponsorship` };

            case 32:
            case 34:
                if (item.sportLaunchType) {
                    switch (item.sportLaunchType) {
                        case "DEEPLINK":
                            return { href: `/กีฬา/im?sportId=${item.sportId}&eventId=${item.eventId}` };
                        case "HOME":
                            return { href: `/กีฬา/im` };
                        default:
                            break;
                    }
                }

                // 查找對應的 gameCateCode
                const gameCategory = this.props.GameCategory.find(cat=>cat.subProviders.some(sub=>sub.code === item.cgmsVendorCode));
                const gameCategoryCode = gameCategory?.code || "";
                const gameCategoryItem = mapGameProduct.find(item=>item.productCode === gameCategoryCode);
                if (!gameCategoryItem) {
					return { href: `/` };
				}
                // 查找對應的 providerName
                const providerName = getGame2ndLevelRouteName(gameCategoryCode, item.cgmsVendorCode);
                if (!providerName) {
                    return { href: `/` };
				}
                if (item.launchMode == "game_id") {
                    return { href: `/${gameCategoryItem.name}/${providerName}?gameid=${item.gameId}` };
                }
                if (item.launchMode == "lobby" || item.launchMode == "web_view") {
                    return { href: `/${gameCategoryItem.name}/${providerName}` };
                }
                break;
            default:
                break;
        }
        return null;
    }

    

    render() {
        const { WalletCloseBanner } = this.state;
        const { item, type, height, width, imgType, bannerFlag, bannerList } =
            this.props;
        const BannerHref = this.getBannerHref(item);
        
        return (
            <React.Fragment>
                {!WalletCloseBanner && (
                    <LinkHelper
                        href={BannerHref?.href} 
                        as={BannerHref?.as}
                        blockHrefRedirection
                    >
                    <div
                        className="BannerBox"
                        style={{ padding: !type || type == "home" ? "0" : "" }}
                    >
                        <React.Fragment>
                            {type == "home" ? (
                  
                                    <Image
                                        src={item?.cmsImageUrl || "//th/img/logo.svg"}
                                        width={width}
                                        height={height}
                                        style={{
                                            display: "block",
                                        }}
                                        onClick={() => {
                                            this.BannerAction(item);
                                        }}
                                        alt={item ? item.title : ""}
                                        priority
                                    />
                           
                            ) : (
                                
                                      <Image
                                          src={item?.cmsImageUrl || "//th/img/logo.svg"}
                                          priority
                                          width={width ? width : 360}
                                          height={height ? height : 70}
                                          onClick={() => {
                                              this.BannerAction(item);
                                          }}
                                          alt={item ? item.title : ""}
                                      />
                        
                            )}
                        </React.Fragment>
                    </div>
                    </LinkHelper>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        GameCategory: state.game.categories,
    };
};
const mapDispatchToProps = function (dispatch) {
    return {
        changeTab: (i) => {
            dispatch(promotionActions.changeTab(i));
        },
        setCacheGame: (data) => {
            dispatch(gameActions.setCacheGame(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
